import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { HeaderOneComponent } from './header-one.component';

export const routes = [
    { path: '', component: HeaderOneComponent, pathMatch: 'full' },
  
    ];
    
    @NgModule({
        imports: [
        CommonModule,
        RouterModule.forChild(routes),
        ReactiveFormsModule
        ],
        declarations: [
            HeaderOneComponent
        ],
        exports: [
            HeaderOneComponent
        ]
    })

export class HeaderOneModule { }