import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { ScrollupComponent } from './scrollup.component';

export const routes = [
    { path: '', component: ScrollupComponent, pathMatch: 'full' },
  
    ];
    
    @NgModule({
        imports: [
        CommonModule,
        RouterModule.forChild(routes),
        ReactiveFormsModule,
        ],
        declarations: [
            ScrollupComponent
        ],
        exports: [
            ScrollupComponent
        ]
    })

export class ScrollupModule { }