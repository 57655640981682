<section id="contact" class="bg-gray ptb_100">
    <div class="fondo_left_contact">
        <img src="assets/img/hotdog.png" class="image-5" alt="Hotdog - Yuuju">
    </div>
    <div class="container">
        <!--<div fxLayout="row wrap" fxLayoutAlign="center center" class="py-3 border-bottom-gray theme-container">
            <div fxFlex="100" fxFlex.gt-sm="80" fxFlex.sm="30">
                <div class="row justify-content-center">
                    <div class="col-12 col-md-10 col-lg-6">
                        <!- Section Heading --
                        <div class="section-heading text-center section-heading-bottom">
                            <h2 class="text-capitalize">{{title}}</h2>
                        </div>
                    </div>
                </div>
                <p class="description">
                    {{paragraph}}
                </p>
            </div>
        </div>-->
        <div class="row justify-content-center">
            <div class="col-12 col-md-10 col-lg-6">
                <!-- Section Heading -->
                <div class="section-heading text-center">
                    <h2 class="text-capitalize">Contáctanos</h2>
                </div>
            </div>
        </div>
        <div class="row justify-content-between">
            <div class="col-12 col-md-12 pt-4 pt-md-0">
                <!-- Contact Box -->
                <div class="contact-box text-center">
                    <!-- Contact Form -->
                    <form id="contact-form" [formGroup]="contactForm" (ngSubmit)="onContactFormSubmit(contactForm.value, formDirective)" #formDirective="ngForm">
                        <div class="row">
                            <div class="col-md-4 col-sm-12">
                                <mat-form-field appearance="outline" class="w-100">
                                    <mat-label>Nombre</mat-label>
                                    <input matInput placeholder="Nombre" formControlName="name" required>
                                    <mat-error *ngIf="contactForm.controls.name.errors?.required">Campo requerido</mat-error>
                                </mat-form-field>
                            </div>
                            <div class="col-md-4 col-sm-12">
                                <mat-form-field appearance="outline" class="w-100">
                                    <mat-label>Correo Electrónico</mat-label>
                                    <input matInput placeholder="Correo Electrónico" formControlName="email" required>
                                    <mat-error *ngIf="contactForm.controls.email.errors?.required">Correo requerido</mat-error>
                                    <mat-error *ngIf="contactForm.controls.email.hasError('invalidEmail')">Correo inválido</mat-error>
                                </mat-form-field>
                            </div>
                            <div class="col-md-4 col-sm-12">
                                <mat-form-field appearance="outline" class="w-100">
                                    <mat-label>Teléfono</mat-label>
                                    <input matInput placeholder="Teléfono *" formControlName="phone" required>
                                    <mat-error *ngIf="contactForm.controls.phone.errors?.required">Teléfono requerido</mat-error>
                                </mat-form-field>
                            </div>
                            <div class="col-12">
                                <mat-form-field appearance="outline" class="w-100">
                                    <mat-label>Mensaje</mat-label>
                                    <textarea matInput placeholder="Mensaje" formControlName="message" required rows="6"></textarea>
                                    <mat-error *ngIf="contactForm.controls.message.errors?.required">Mensaje requerido</mat-error>
                                </mat-form-field>
                            </div>
                        </div>
                        <div class="w-100 py-2 text-center">
                            <button type="submit" class="btn btn-lg mt-3"><span class="text-white pr-3"><i class="fas fa-paper-plane"></i></span>ENVIAR</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
    <div fxHide.xs class="fondo_right_contact">
        <img src="assets/img/Munchkin.png" class="image-4" alt="Munchkin - Yuuju">
    </div>
</section>