<section id="brand" class="section features-area style-two overflow-hidden bg-gray ptb_100">
    <div class="fondo_img_left_brand">
        <img src="assets/img/pollo.png" class="image-5" alt="pollo">
    </div>
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-12 col-md-10 col-lg-10">
                <!-- Section Heading -->
                <div class="section-heading text-center">
                    <h2>Pide también en nuestras Páginas Web</h2>
                </div>
            </div>
        </div>
        <div fxLayout="row wrap" class="brand-general">
            <div fxFlex="90" fxFlex.gt-sm="25" fxFlex.sm="50" class="col">
                <mat-card class="image-box text-center icon-1 wow fadeInLeft" data-aos-duration="2s" data-wow-delay="0.4s">
                    <a class="featured-img">
                        <img class="avatar-sm" src="assets/img/iconos_brand/Burger-king-logo-yuuju.jpg" alt="Logo de Burger King - Yuuju">
                    </a>
                    <h3 class="mb-2">Burger King</h3>
                    <div class="description_brand">
                        <p>Whopper, King de Pollo, Hamburguesas, Nuggets, Postres…</p>
                    </div>
                    <div class="icons">
                        <a href="https://www.burgerkinghonduras.hn/" target="_blank" class="btn btn-lg active" role="button" aria-pressed="true">¡ORDENAR AQUÍ!</a>
                    </div>
                </mat-card>
            </div>
            <div fxFlex="90" fxFlex.gt-sm="25" fxFlex.sm="50" class="col">
                <mat-card class="image-box text-center icon-1 wow fadeInLeft" data-aos-duration="2s" data-wow-delay="0.4s">
                    <a class="featured-img">
                        <img class="avatar-sm" src="assets/img/iconos_brand/Little-caesars-logo-yuuju.jpg" alt="Logo de Little Caesars - Yuuju">
                    </a>
                    <h3 class="mb-2">Little Caesars</h3>
                    <div class="description_brand">
                        <p>Pizza, Pan Loco, Alitas, Canelitas...</p>
                    </div>
                    <div class="icons">
                        <a href="https://www.littlecaesars.hn/" target="_blank" class="btn btn-lg active" role="button" aria-pressed="true">¡ORDENAR AQUÍ!</a>
                    </div>
                </mat-card>
            </div>
            <div fxFlex="90" fxFlex.gt-sm="25" fxFlex.sm="50" class="col">
                <mat-card class="image-box text-center icon-1 wow fadeInLeft" data-aos-duration="2s" data-wow-delay="0.4s">
                    <a class="featured-img">
                        <img class="avatar-sm" src="assets/img/iconos_brand/Popeyes-logo-yuuju.jpg" alt="Logo de Popeyes - Yuuju">
                    </a>
                    <h3 class="mb-2">Popeyes</h3>
                    <div class="description_brand">
                        <p>Louisiana Style Chicken, Pollo, Nuggets, Tenders, Biscuits…</p>
                    </div>
                    <div class="icons">
                        <a href="https://popeyeshonduras.hn/" target="_blank" class="btn btn-lg active" role="button" aria-pressed="true">¡ORDENAR AQUÍ!</a>
                    </div>
                </mat-card>
            </div>
            <div fxFlex="90" fxFlex.gt-sm="25" fxFlex.sm="50" class="col">
                <mat-card class="image-box text-center icon-1 wow fadeInLeft" data-aos-duration="2s" data-wow-delay="0.4s">
                    <a class="featured-img">
                        <img class="avatar-sm" src="assets/img/iconos_brand/Dunkin-donuts-logo-yuuju.jpg" alt="Logo de Dunkin' Donuts - Yuuju">
                    </a>
                    <h3 class="mb-2">Dunkin'</h3>
                    <div class="description_brand">
                        <p>Café, Donas, Sandwich...</p>
                    </div>
                    <div class="icons">
                        <a style="bottom: 0px;" href="https://dunkin.hn/" target="_blank" class="btn btn-lg active" role="button" aria-pressed="true">¡ORDENAR AQUÍ!</a>
                    </div>
                </mat-card>
            </div>
            <div fxFlex="90" fxFlex.gt-sm="25" fxFlex.sm="50" class="col">
                <mat-card class="image-box text-center icon-1 wow fadeInLeft" data-aos-duration="2s" data-wow-delay="0.4s">
                    <a class="featured-img">
                        <img class="avatar-sm" src="assets/img/iconos_brand/Baskin-robbins-logo-yuuju.jpg" alt="Logo de Baskin Robbins - Yuuju">
                    </a>
                    <h3 class="mb-2">Baskin Robbins</h3>
                    <div class="description_brand">
                        <p>Helados, Malteadas, Pasteles...</p>
                    </div>
                    <div class="icons">
                        <a href="https://baskin.hn/" target="_blank" class="btn btn-lg active" role="button" aria-pressed="true">¡ORDENAR AQUÍ!</a>
                    </div>
                </mat-card>
            </div>
            <div fxFlex="90" fxFlex.gt-sm="25" fxFlex.sm="50" class="col">
                <mat-card class="image-box text-center icon-1 wow fadeInLeft" data-aos-duration="2s" data-wow-delay="0.4s">
                    <a class="featured-img">
                        <img class="avatar-sm" src="assets/img/iconos_brand/Churchs-chicken-logo-yuuju.jpg" alt="Logo de Church's Chicken - Yuuju">
                    </a>
                    <h3 class="mb-2">Church's Chicken</h3>
                    <div class="description_brand">
                        <p>Pollo Frito, Panchos, Jalapeños Bombers, Biscuits... </p>
                    </div>
                    <div class="icons">
                        <a style="bottom: 0px;" href="https://churchshonduras.hn/" target="_blank" class="btn btn-lg active" role="button" aria-pressed="true">¡ORDENAR AQUÍ!</a>
                    </div>
                </mat-card>
            </div>
            <div fxFlex="90" fxFlex.gt-sm="25" fxFlex.sm="50" class="col">
                <mat-card class="image-box text-center icon-1 wow fadeInLeft" data-aos-duration="2s" data-wow-delay="0.4s">
                    <a class="featured-img">
                        <img class="avatar-sm" src="assets/img/iconos_brand/Campero-logo-yuuju.jpg" alt="Logo de Campero - Yuuju">
                    </a>
                    <h3 class="mb-2">Pollo Campero</h3>
                    <div class="description_brand">
                        <p>Pollo Frito y Asado, Banquetes, Nuggets, Alitas...</p>
                    </div>
                    <div class="icons">
                        <a href="https://campero.hn/" target="_blank" class="btn btn-lg active" role="button" aria-pressed="true">¡ORDENAR AQUÍ!</a>
                    </div>
                </mat-card>
            </div>
            <div fxFlex="90" fxFlex.gt-sm="25" fxFlex.sm="50" class="col">
                <mat-card class="image-box text-center icon-1 wow fadeInLeft" data-aos-duration="2s" data-wow-delay="0.4s">
                    <a class="featured-img">
                        <img class="avatar-sm" src="assets/img/iconos_brand/Chilis-logo-yuuju.jpg" alt="Logo de Chili's - Yuuju">
                    </a>
                    <h3 class="mb-2">Chili's</h3>
                    <div class="description_brand">
                        <p>Hamburguesas, Fajitas, Crispers, Margaritas, Baby Back Ribs, Postres...</p>
                    </div>
                    <div class="icons">
                        <a href="https://chilis.hn/" target="_blank" class="btn btn-lg active" role="button" aria-pressed="true">¡ORDENAR AQUÍ!</a>
                    </div>
                </mat-card>
            </div>
            <div fxFlex="90" fxFlex.gt-sm="25" fxFlex.sm="50" class="col">
                <mat-card class="image-box text-center icon-1 wow fadeInLeft" data-aos-duration="2s" data-wow-delay="0.4s">
                    <a class="featured-img">
                        <img class="avatar-sm" src="assets/img/iconos_brand/Its-just-wings-logo-yuuju.jpg" alt="Logo de It's Just Wings - Yuuju">
                    </a>
                    <h3 class="mb-2">It's Just Wings</h3>
                    <div class="description_brand">
                        <p>Alitas de Pollo</p>
                    </div>
                    <div class="icons">
                        <a href="https://www.itsjustwings.hn/" target="_blank" class="btn btn-lg active" role="button" aria-pressed="true">¡ORDENAR AQUÍ!</a>
                    </div>
                </mat-card>
            </div>
            <div fxFlex="90" fxFlex.gt-sm="25" fxFlex.sm="50" class="col">
                <mat-card class="image-box text-center icon-1 wow fadeInLeft" data-aos-duration="2s" data-wow-delay="0.4s">
                    <a class="featured-img">
                        <img class="avatar-sm" src="assets/img/iconos_brand/Circle-k-logo-yuuju.jpg" alt="Logo de Circle K - Yuuju">
                    </a>
                     <h3 class="mb-2">Circle K</h3>
                    <div class="description_brand">
                        <p>Big Dogs, Licores, Bebidas Empacadas, Snacks, Abarrotes...</p>
                    </div>
                    <div class="icons">
                        <a href="https://circlek.hn/" target="_blank" class="btn btn-lg active" role="button" aria-pressed="true">¡ORDENAR AQUÍ!</a>
                    </div>
                </mat-card>
            </div>
            
        </div>
    </div>
</section>
