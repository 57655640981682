import { Component } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { CompanyService } from './services/company.service';

declare var gtag;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'sapp';

  constructor(
    private companyService: CompanyService,
    public router: Router,
  ){
    
    const navEndEvents$ = this.router.events
      .pipe(
        filter(event => event instanceof NavigationEnd)
      );

    navEndEvents$.subscribe((event: NavigationEnd) => {

      gtag('config', 'UA-205206555-1', {
        'page_path': event.urlAfterRedirects
      });

    });
  }

  ngOnInit() {
    this.getCompany()
  }

  getCompany() {
    this.companyService.getConfigCompany().subscribe(res => {
      console.log("res")
    })
  }
}
