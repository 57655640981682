import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { FooterOneComponent } from './footer-one.component';

export const routes = [
    { path: '', component: FooterOneComponent, pathMatch: 'full' },
  
    ];
    
    @NgModule({
        imports: [
        CommonModule,
        RouterModule.forChild(routes),
        ReactiveFormsModule
        ],
        declarations: [
            FooterOneComponent
        ],
        exports: [
            FooterOneComponent
        ]
    })

export class FooterOneModule { }