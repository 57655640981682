import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-fast-feature',
  templateUrl: './fast-feature.component.html',
  styleUrls: ['./fast-feature.component.css']
})
export class FastFeatureComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
