<div class="main">
    <app-download></app-download>
    <app-scrollup></app-scrollup>
    <app-header-one></app-header-one>
    <app-welcome-one></app-welcome-one>
    <app-fast-feature></app-fast-feature>
    <app-brand-two></app-brand-two>
    <app-feature-four></app-feature-four>
    <app-contact></app-contact>
    <app-footer-one></app-footer-one>
</div>