<section class="section features-area style-two overflow-hidden">
    <div class="fondo_img">
        <img src="assets/img/Little-caesars-pizza-yuuju.png" class="image-1" alt="Little-caesars-pizza - Yuuju">
    </div>
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-12 col-md-10 col-lg-12">
                <!-- Section Heading -->
                <div class="section-heading text-center">
                    <h2>Pide como quieras en Yuuju!</h2>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-12 col-md-12 col-lg-4">
                <!-- Icon Box -->
                <div class="icon-box text-center p-4 wow fadeInUp" data-wow-duration="2s" data-wow-delay="0.4s">
                    <!-- Featured Icon -->
                    <div class="featured-icon mb-3">
                        <mat-icon>directions_car</mat-icon>
                    </div>
                    <!-- Icon Text -->
                    <div class="icon-text">
                        <h3 class="mb-2">Recoge en autoservicio</h3>
                        <p>Elige el día y la hora de tu preferencia</p>
                    </div>
                </div>
            </div>
            <div class="col-12 col-md-12 col-lg-4">
                <!-- Icon Box -->
                <div class="icon-box text-center p-4 wow fadeInUp" data-wow-duration="2s" data-wow-delay="0.2s">
                    <!-- Featured Icon -->
                    <div class="featured-icon mb-3">
                        <mat-icon>directions_walk</mat-icon>
                    </div>
                    <!-- Icon Text -->
                    <div class="icon-text">
                        <h3 class="mb-2">Recoge en restaurante</h3>
                        <p>Pide aquí y elige dónde y cuándo recoger</p>
                    </div>
                </div>
            </div>
            <div class="col-12 col-md-12 col-lg-4">
                <!-- Icon Box -->
                <div class="icon-box text-center p-4 wow fadeInUp" data-wow-duration="2s">
                    <!-- Featured Icon -->
                    <div class="featured-icon mb-3">
                        <mat-icon>moped</mat-icon>
                    </div>
                    <!-- Icon Text -->
                    <div class="icon-text">
                        <h3 class="mb-2">Pide a domicilio</h3>
                        <p>Te llevamos tu pedido a donde estés</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="fondo_img_brand">
        <img src="assets/img/whopper.png" class="image-3" alt="Whopper - Yuuju">
    </div>
</section>
