<header class="navbar navbar-sticky navbar-expand-lg navbar-dark">
    <div class="container position-relative">
        <a class="navbar-brand" href="/">
            <img class="navbar-brand-regular" src="assets/img/yuuju_app.png" alt="Logo - Yuuju">
            <img class="navbar-brand-sticky" src="assets/img/yuuju_app_dark.png" alt="Logo - Yuuju">
        </a>
        <button class="navbar-toggler d-lg-none" type="button" data-toggle="navbarToggler" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
        </button>

        <div class="navbar-inner">
            <!--  Mobile Menu Toggler -->
            <button class="navbar-toggler d-lg-none" type="button" data-toggle="navbarToggler" aria-label="Toggle navigation">
                <span class="navbar-toggler-icon"></span>
            </button>
            <nav>
                <ul class="navbar-nav" id="navbar-nav">
                    <li class="nav-item dropdown">
                        <a class="nav-link scroll" href="#home">Inicio</a>
                    </li>
                    <li class="nav-item dropdown">
                        <a class="nav-link scroll" href="#brand">Nuestras Web</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link scroll" href="#features">Beneficios</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link scroll" href="#contact">Contáctanos</a>
                    </li>
                </ul>
                <a class="nav-logo">
                    <img src="assets/img/yuuju_app_dark.png" alt="Logo - Yuuju">
                </a>
            </nav>
        </div>
    </div>
</header>