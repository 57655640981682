<section class="section discover-area bg-gray overflow-hidden ptb_100">
    <div class="container">
        <div class="row justify-content-between align-items-center">
            
            <div class="col-12 col-lg-6 order-1 order-lg-1">
                <!-- Discover Text -->
                <div class="discover-text pt-4 pt-lg-0 px-0 px-lg-4">
                    <h2 class="pb-4">¿Por qué Yuju?</h2>
                    <!-- Service List -->
                    <ul class="service-list style-two">
                        <!-- Single Service -->
                        <li class="single-service py-2">
                            <div class="service-text">
                                <p>Fully layered dolor sit amet, consectetur adipisicing elit. Facere, nobis, id expedita dolores officiis laboriosam.</p>
                            </div>
                        </li>
                        <!-- Single Service -->
                        <li class="single-service py-2">
                            <div class="service-text">
                                <p>Customizable design dolor sit amet, consectetur adipisicing elit. Facere, nobis, id expedita dolores officiis laboriosam.</p>
                            </div>
                        </li>
                        <!-- Single Service -->
                        <li class="single-service py-2">
                            <div class="service-text">
                                <p>Drop ipsum dolor sit amet, consectetur adipisicing elit. Facere, nobis, id expedita dolores officiis laboriosam.</p>
                            </div>
                        </li>
                        <!-- Single Service -->
                        <li class="single-service py-2">
                            <div class="service-text">
                                <p>Marketing chart dolor sit amet, consectetur adipisicing elit. Facere, nobis, id expedita dolores officiis laboriosam.</p>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="col-12 col-lg-6 order-2 order-lg-2">
                <!-- Discover Thumb -->
                <div class="service-thumb discover-thumb mx-auto">
                    <img src="assets/img/discover_thumb.png" alt="">
                </div>
            </div>
        </div>
    </div>
</section>