<section id="home" class="section welcome-area bg-overlay overflow-hidden d-flex align-items-center">
    <div class="container">
        <div class="row align-items-center">
            <!-- Welcome Intro Start -->
            <div class="col-12 col-md-7 col-lg-6">
                <div class="welcome-intro">
                    <!--<img src="assets/img/yuuju_app.png" alt="">-->
                    <div class="text-center">
                        <h3 style="display: inline-block;" class="text-black text-center">
                            La mejor APP de Honduras
                        </h3>
                        <h3 class="text-white text-center mb-4">
                            para pedir Comida, Bebidas <br>
                            y Productos de Conveniencia
                        </h3>
                    </div>
                    <h1 class="text-white text-center"> ¡Descárgala YA!</h1>
                    <div fxHide fxShow.gt-xs fxLayout="row" fxLayoutAlign="space-around center" class="icons-download">
                        <div class="contain-icon">
                            <a (click)="openGeneralLink('https://itunes.apple.com/hn/app/yuuju/id1572499631','iPhone')">
                                <img src="assets/img/apple-app-store-badge-es.png" alt="App Store - Yuuju">
                            </a>
                        </div>
                        <div class="contain-icon">
                            <a (click)="openGeneralLink('http://play.google.com/store/apps/details?id=com.yuuju.intur.albatros','Android')">
                                <img src="assets/img/google-play-badge.png" alt="Google Play - Yuuju">
                            </a>
                        </div>
                    </div>
                    <div fxHide fxShow.gt-xs fxLayout="row" fxLayoutAlign="space-around center" style="padding: 5px 28%;">
                        <div class="contain-icon">
                            <a (click)="openGeneralLink('https://appgallery.cloud.huawei.com/marketshare/app/C104650549','Huawei')">
                                <img src="assets/img/appGallery-es.png" alt="Gallery Store - Yuuju">
                            </a>
                        </div>
                    </div>
                    <div *ngIf="showButton" fxHide fxShow.gt-xs fxLayout="row" fxLayoutAlign="space-around center" style="padding: 10px 28%;">
                        <!--<a href="https://n385.network-auth.com/Pruebasp/hi/X439GaE/grant?continue_url=http://google.com/" (click)="freeNavigation()" class="btn btn-secondary" role="button" aria-pressed="true">Continuar Navegando</a>-->
                        <a class="btn btn-lg" role="button" href="https://n385.network-auth.com/Pruebasp/hi/X439GaE/grant?continue_url=http://google.com/" onclick="javascript:(function() {
                            var _request = new XMLHttpRequest();
                            var url = 'https://n385.network-auth.com/Pruebasp/hi/X439GaE/grant?continue_url=CONTINUE_URL_PLACEHOLDER';
                            _request.open('HEAD', window.location, true);
                            _request.setRequestHeader('X-Requested-With', 'XMLHttpRequest');
                            _request.onreadystatechange = function() {
                              if (_request.readyState === 4) {
                                var continue_url = _request.getResponseHeader('Continue-Url');
                                window.location.href = url.replace('CONTINUE_URL_PLACEHOLDER', continue_url);
                              };
                            };
                            _request.send(null);
                        })(); return false;" title="Continuar Navegando"><i class="fas fa-paper-plane"></i> Continuar Navegando</a>
                    </div>

                    <div fxShow fxHide.gt-xs fxLayout="column" fxLayoutAlign="space-around center" class="icons-download">
                        <div class="contain-icon">
                            <a (click)="openGeneralLink('https://itunes.apple.com/hn/app/yuuju/id1572499631','iPhone')">
                                <img src="assets/img/apple-app-store-badge-es.png" alt="App Store - Yuuju">
                            </a>
                        </div>
                        <div class="contain-icon">
                            <a (click)="openGeneralLink('http://play.google.com/store/apps/details?id=com.yuuju.intur.albatros','Android')">
                                <img src="assets/img/google-play-badge.png" alt="Google Play - Yuuju">
                            </a>
                        </div>
                        <div class="contain-icon">
                            <a (click)="openGeneralLink('https://appgallery.cloud.huawei.com/marketshare/app/C104650549','Huawei')">
                                <img src="assets/img/appGallery-es.png" alt="Gallery Store - Yuuju">
                            </a>
                        </div>
                        <div *ngIf="showButton" style="padding: 10px">
                            <a class="btn btn-lg" role="button" href="https://n385.network-auth.com/Pruebasp/hi/X439GaE/grant?continue_url=http://google.com/" onclick="javascript:(function() {
                                var _request = new XMLHttpRequest();
                                var url = 'https://n385.network-auth.com/Pruebasp/hi/X439GaE/grant?continue_url=CONTINUE_URL_PLACEHOLDER';
                                _request.open('HEAD', window.location, true);
                                _request.setRequestHeader('X-Requested-With', 'XMLHttpRequest');
                                _request.onreadystatechange = function() {
                                  if (_request.readyState === 4) {
                                    var continue_url = _request.getResponseHeader('Continue-Url');
                                    window.location.href = url.replace('CONTINUE_URL_PLACEHOLDER', continue_url);
                                  };
                                };
                                _request.send(null);
                            })(); return false;" title="Continuar Navegando"><i class="fas fa-paper-plane"></i> Continuar Navegando</a>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12 col-md-5 col-lg-6">
                <!-- Welcome Thumb -->
                <div class="welcome-thumb mx-auto img-mobile-1" data-aos="fade-left" data-aos-delay="500" data-aos-duration="1000">
                    <img src="assets/img/home.png" alt="App Móvil- Yuuju">
                    <!--<img src="assets/img/welcome_mockup.png" alt="">-->
                </div>
            </div>
        </div>
        <div class="shape-bottom">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320" fill="#FFFFFF">
                <path fill-opacity="1" d="M0,288L0,160L1440,160L1440,320L0,320L0,320Z"></path>
            </svg>
        </div>
    </div>
</section>