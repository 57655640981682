<section id="features" class="section features-area overflow-hidden mt-5 ptb_100">
    <div class="fondo_img_feature">
        <img src="assets/img/dona.png" class="image-3" alt="Donuts - Yuuju">
    </div>
    <div fxHide.xs class="fondo_img_left_feature">
        <img src="assets/img/CS.png" class="image-5" alt="CS - Yuuju">
    </div>
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-12 col-md-10 col-lg-10">
                <!-- Section Heading -->
                <div class="section-heading text-center">
                    <h2>Pedir con Yuuju!, es muy fácil</h2>
                </div>
            </div>
        </div>
        <div class="row align-items-center">
            <div class="features-text col-12 col-md-6">
                <ul class="features-item">
                    <li>
                        <!-- Image Box -->
                        <div class="image-box media icon-1 px-1 py-3 py-md-4 wow fadeInRight" data-aos-duration="2s" data-wow-delay="0.2s">
                            <!-- Featured Image -->
                            <div class="featured-img mr-3">
                                <!--<img class="avatar-sm" src="assets/img/icon1.png" alt="">-->
                                <img src="assets/img/pago.png" alt="tarjetas - Yuuju">                                
                            </div>
                            <!-- Icon Text -->
                            <div class="icon-text media-body align-self-center align-self-md-center">
                                <p>Puedes pagar con Tarjeta de crédito, débito o en efectivo.</p>
                            </div>
                        </div>
                    </li>
                    <li>
                        <!-- Image Box -->
                        <div class="image-box media icon-2 px-1 py-3 py-md-4 wow fadeInRight" data-aos-duration="2s" data-wow-delay="0.4s">
                            <!-- Featured Image -->
                            <div class="featured-img mr-3">
                                <img  src="assets/img/comida.png" alt="promociones - Yuuju">
                            </div>
                            <!-- Icon Text -->
                            <div class="icon-text media-body align-self-center align-self-md-center">
                                <p>Disfruta promociones exclusivas en Yuuju!</p>
                            </div>
                        </div>
                    </li>
                    <li>
                        <!-- Image Box -->
                        <div class="image-box media icon-3 px-1 py-3 py-md-4 wow fadeInRight" data-aos-duration="2s" data-wow-delay="0.6s">
                            <!-- Featured Image -->
                            <div class="featured-img mr-3">
                                <img src="assets/img/chat.png" alt="chat - Yuuju" style="width: 7em; height:7em">
                            </div>
                            <!-- Icon Text -->
                            <div class="icon-text media-body align-self-center align-self-md-center">
                                <p>Chat de atención inmmediata.</p>
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
            <div class="col-12 col-md-6 text-center">
                <img class="mobile-2" src="assets/img/lista-producto.png" alt="App Móvil- Yuuju">
                <!--<div class="features-slider-wrapper overflow-hidden">
                    !-- Work Slider --
                    <ul class="features-slider owl-carousel">
                        <li class="slide-item">
                            <img src="assets/img/screenshot_1.jpg" alt="">
                        </li>
                        <li class="slide-item">
                            <img src="assets/img/screenshot_2.jpg" alt="">
                        </li>
                    </ul>
                </div>-->
            </div>
        </div>
    </div>
</section>