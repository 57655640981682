import { Component, OnInit } from '@angular/core';
import { DeviceDetectorService } from 'ngx-device-detector';

@Component({
  selector: 'app-welcome-one',
  templateUrl: './welcome-one.component.html',
  styleUrls: ['./welcome-one.component.css']
})
export class WelcomeOneComponent implements OnInit {

  detector: boolean
  mobile: any
  showButton: boolean = false

  constructor(
    private deviceService: DeviceDetectorService
  ) { 

  }

  ngOnInit(): void {
    if(window.location.href.endsWith('?call=0')){
      this.showButton = true
    }

    this.detector = this.deviceService.isMobile()
    if(this.detector){
      let info = this.deviceService.getDeviceInfo()
      this.mobile = info.device
    }
  }

  openGeneralLink(url,type){
    if(this.detector && this.mobile){
      if(this.mobile == type){
        if(type == 'Android' || type == 'Huawei'){
          window.open('market://details?id=com.yuuju.intur.albatros')
        }else if(type== 'iPhone'){
          window.open('itms-apps://itunes.apple.com/app/id1572499631')
        }
      }
      else if(type == 'Huawei' && (this.mobile != 'Android' && this.mobile != 'iPhone')){
        window.open('market://details?id=com.yuuju.intur.albatros')
      }
      else{ 
        window.open(url, '_blank')
      }
    }else{
      window.open(url, '_blank')
    }
  }

  freeNavigation() {
    var _request = new XMLHttpRequest();
    var url = 'https://n385.network-auth.com/Pruebasp/hi/X439GaE/grant?continue_url=CONTINUE_URL_PLACEHOLDER';
    _request.open('HEAD', window.location.href, true); //url -> window.location
    _request.setRequestHeader('X-Requested-With', 'XMLHttpRequest');
    _request.onreadystatechange = function() {
      if (_request.readyState === 4) {
        var continue_url = _request.getResponseHeader('Continue-Url');
        window.location.href = url.replace('CONTINUE_URL_PLACEHOLDER', continue_url);
      };
    };
    _request.send(null);
  }
}
