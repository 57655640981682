<div *ngIf="viewLayer && windowWidth <= 600" class="wrapper content-download" [ngStyle]="{
    'right': '0%', 
    'width': '100%'}">
    <div class="content-layer" fxLayout="column">
        <div fxLayout="row" fxLayoutAlign="space-between center" class="text-center">
            <span class="icon-layer" (click)="closeLayer()">
                <i class="fas fa-times"></i>
            </span>
            <div fxFlex="70" fxLayout="row" fxLayoutAlign=" center">
                <img src="assets/img/yuuju_app_dark.png" class="logo-img" alt="Yuuju - Honduras" />
                <div fxLayout="column" fxLayoutAlign=" start" class="content-des">
                    <span><b>yuuju.hn</b></span>
                    <!-- <app-rating [ratingsCount]="5" [ratingsValue]="5" [direction]="'column'"></app-rating> -->
                    <div class="layer-des">
                        OBTENER - EN {{this.os == 'Android' ? 'Play Store' : (this.os == 'Huawei' ? 'App Gallery' : 'App Store')}}
                    </div>
                </div>
            </div>
            <a class="secondary-color" (click)="downloadYuuju()">
                Instalar
            </a>
        </div>
    </div>
</div>