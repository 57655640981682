<div class="homepage-6">
    <div class="main">
        <app-scrollup></app-scrollup>
        <app-header-one></app-header-one>
        <app-welcome-six></app-welcome-six>
        <app-service-six></app-service-six>
        <app-discover-six></app-discover-six>
        <app-brand-one></app-brand-one>
        <app-work></app-work>
        <app-review-three></app-review-three>
        <app-feature-six></app-feature-six>
        <app-screenshot-one></app-screenshot-one>
        <app-pricing-four></app-pricing-four>
        <app-team></app-team>
        <app-download></app-download>
        <app-subscribe></app-subscribe>
        <app-contact></app-contact>
        <app-footer-one></app-footer-one>
    </div>
</div>