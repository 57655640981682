<div class="inner inner-pages">
    <div class="main">
        <section id="home" class="section background-not-found h-100vh overflow-hidden">
            <div class="container h-100">
                <div class="row align-items-center justify-content-center h-100">
                    <div class="col-12 col-md-6">
                        <div class="error-area text-center">
                            <h2 class="text">404</h2>
                            <p class="text my-4">Es posible que la página que está buscando se haya eliminado o no está disponible temporalmente.</p>
                            <a href="/" class="btn sApp-btn text-uppercase">Ir a Inicio</a>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</div>