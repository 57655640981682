import { config } from './../../environments/config';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class CompanyService {
    private URL = ''
    private currentCompanySubject: BehaviorSubject<any>;
    public currenCompany: Observable<any>;

    constructor(
        private http: HttpClient
    ) {
        this.URL = config.domain
        this.currentCompanySubject = new BehaviorSubject<any>(JSON.parse(sessionStorage.getItem('currentCompany')));
        this.currenCompany = this.currentCompanySubject.asObservable();
    }

    public get currentCompanyValue(): any {
        return this.currentCompanySubject.value;
    }

    //esto sirve para obtener todo de company desde el inicio
    getConfigCompany() {
        let url_api = this.URL + 'api/em/company/get/'
        return this.http.get<any>(url_api)
          .pipe(map(res => {
            sessionStorage.setItem('currentCompany', JSON.stringify(res));
            this.currentCompanySubject.next(res);
            return res;
          }));
    }
}