import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-footer-one',
  templateUrl: './footer-one.component.html',
  styleUrls: ['./footer-one.component.css']
})
export class FooterOneComponent implements OnInit {

  today: number = Date.now();
  
  constructor(
    private router: Router,
  ) { }

  ngOnInit(): void {
  }

  irPolitics(type, name) {
    this.router.navigate(['politicas', type]) //, ''
  }
}
