<!--<div class="height-emulator d-none d-lg-block"></div>-->
<footer id="contact" class="footer-area bg-gray">
    <!-- Footer Bottom -->
    <div class="footer-bottom">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <!-- Copyright Area -->
                    <div class="copyright-area d-flex flex-wrap justify-content-center justify-content-sm-between text-center py-4">
                        <!-- Copyright Left -->
                        <div class="copyright-left">
                            <a href="/politicas/T">Términos y Condiciones</a> <span class="separator">|</span>
                            <a href="/politicas/P">Política de Protección de Datos Personales</a>
                        </div>
                        <!-- Copyright Right -->
                        <div class="copyright-right">&copy; {{today | date:'yyyy'}}
                            <a href="https://www.albatrosservices.com/" target="_blank"> Albatros Services</a>
                        </div>
                    </div>
                </div>
               
            </div>
        </div>
    </div>
    <div class="footer-expanded col-12">
        <div class="footer-final d-flex flex-wrap justify-content-center justify-content-sm-between text-center py-3 px-5">
            <div class="footer-text text-white">
                <span>Todos los derechos exclusivos</span>
            </div>
            <div>
                <img src="assets/img/yuuju_app.png" alt="Logo - Yuuju">
            </div>
        </div>
    </div>
</footer>