import { ChangeDetectorRef, Component, HostListener, OnInit } from '@angular/core';
import { DeviceDetectorService } from 'ngx-device-detector';

@Component({
  selector: 'app-download',
  templateUrl: './download.component.html',
  styleUrls: ['./download.component.css']
})
export class DownloadComponent implements OnInit {

  windowWidth = 0
  detector: boolean
  mobile: any
  os: any
  viewLayer = true

  constructor(
    private cdr: ChangeDetectorRef,
    private deviceService: DeviceDetectorService
  ) { }

  ngOnInit(): void {
    if (window.innerWidth > 601) {
      this.viewLayer = false;
    };

    this.detector = this.deviceService.isMobile()
    if(this.detector){
      let info = this.deviceService.getDeviceInfo()
      this.mobile = info.device
      this.os = info.os
    }
  }

  @HostListener('window:resize')
  public onWindowResize(): void {
    this.windowWidth = window.innerWidth
    this.cdr.detectChanges()
  }

  downloadYuuju(){
    if(this.detector && this.mobile){
      if(this.mobile == 'Android' || this.mobile == 'Huawei'){
        window.open('market://details?id=com.yuuju.intur.albatros')
      }else if(this.mobile == 'iPhone'){
        window.open('itms-apps://itunes.apple.com/app/id1572499631')
      }
      else{ 
        //'https://itunes.apple.com/hn/app/yuuju/id1572499631' iPhone
        //'http://play.google.com/store/apps/details?id=com.yuuju.intur.albatros' Android
        // 'https://appgallery.cloud.huawei.com/marketshare/app/C104650549' Huawei
        //window.open(url, '_blank')
      }
    }
  }

  closeLayer(){
    this.viewLayer = false
  }
}
